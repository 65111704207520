import InviteUser from '@/models/InviteUser';
import Component from 'vue-class-component';
import { inviteService } from '@/main';
import BasePage from '@/models/BasePage';

@Component
export default class InvitesOverview extends BasePage {
    public invites: InviteUser[] = [];
    public inviteColumns = [
        { field: 'firstName', title: 'Voornaam' },
        { field: 'lastName', title: 'Achternaam' },
        { field: 'emailAddress', title: 'E-mailadres' },
    ];
    public isPending: boolean = true;

    public async mounted() {
        await this.getInvites();
    }

    private async getInvites() {
        this.isLoading = true;
        this.invites = await inviteService.invites(this.isPending);
        this.isLoading = false;
    }
}
