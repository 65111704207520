import Component from 'vue-class-component';
import to from 'await-to-js';
import InviteUser from '@/models/InviteUser';
import BasePage from '@/models/BasePage';
import { siteService, sitesHelper, userRoleService, userService } from '@/main';
import Site from '@/models/site';

@Component
export default class InviteView extends BasePage {
    public stepIndex: number = 0;

    public firstname: string = '';
    public lastname: string = '';
    public emailaddress: string = '';

    public selectedRoles: any[] = [];
    public selectedSites: any[] = [];

    public roles: any[] = [{ id: 'SiteAdmin', description: 'Administrator' }];
    public sites: Site[] = [];

    public validationMessages = [
        { step: 0, message: 'Voornaam, achternaam en e-mail adres is verplicht' },
        { step: 1, message: 'De uitgenodigde persoon moet minimaal 1 rol hebben' },
        { step: 2, message: 'De uitgenodigde persoon moet minimaal toegang tot 1 park hebben' },
    ];

    public async mounted() {
        // if (!roleHelper.isSiteAdmin() && !roleHelper.isFinanceEmployee()) {
        //     await this.$router.push({ name: 'not-found' });
        // }

        const roles = await userRoleService.getPermissions();
        this.roles.push(...roles);

        const siteResponse = await siteService.getSites();
        this.sites = siteResponse.data;

        console.log(this.sites);
    }

    public get siteOptions() {
        return this.sites.map((site) => {
            return {
                text: site.name,
                value: site.siteId,
            };
        });
    }

    public get roleOptions() {
        return this.roles.map((role) => {
            return {
                text: role.description,
                value: role.id,
            };
        });
    }

    public valid() {
        this.clearNotifications();

        if (this.stepIndex === 0) {
            return this.firstname !== '' && this.lastname !== '' && this.emailaddress !== '';
        } else if (this.stepIndex === 1) {
            return this.selectedRoles.length > 0;
        } else if (this.stepIndex === 2) {
            return this.selectedSites.length > 0;
        } else if (this.stepIndex === 3) {
            return (this.firstname !== '' && this.lastname !== '' && !this.emailaddress) || this.selectedRoles.length > 0;
        }
    }

    public showValidationError() {
        return this.showValidationErrors(
            this.validationMessages
                .filter((x) => x.step === this.stepIndex)
                .map((x) => {
                    return x.message;
                }),
        );
    }

    public async next() {
        if (this.valid()) {
            this.stepIndex++;
        } else {
            this.showValidationError();
        }
    }

    public back() {
        this.stepIndex--;
    }

    public async finalize() {
        if (this.valid()) {
            await this.inviteMember();
        } else {
            this.showValidationError();
        }
        // create account and send invite.
    }

    public async inviteMember() {
        this.showPending('Uitnodiging wordt verstuurd..');

        const member = {} as InviteUser;
        member.firstName = this.firstname;
        member.lastName = this.lastname;
        member.emailaddress = this.emailaddress;
        member.permissions = [...this.selectedRoles];
        member.sites = [...this.selectedSites];

        // create new member with group memberships
        const [addErr] = await to(userService.inviteUser(member));
        if (addErr) {
            return this.clearAndShowError(`Mislukt om een uitnodiging naar ${this.firstname} ${this.lastname} te sturen.`, addErr);
        }

        this.clearAndShowSuccess('Uitnodiging verstuurd!');

        await this.$router.push({
            name: 'roles',
        });
    }

    public getSiteName(siteId) {
        return this.sites.find((x) => x.siteId === siteId).name;
    }
}
