import PageRender from '../../models/PageRender';
import { priceRuleService } from '@/main';
import Component from 'vue-class-component';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import PriceRule from '../../models/PriceRule';
import to from 'await-to-js';
import Site from '../../models/Site';
import { $router } from '@/router';
import { sitesModule } from '@/store/modules/site';
import GridWrapperSearch from '@prd/shared-ui/dist/services/components/Grid/models/GridWrapperSearch';

@Component
export default class PriceRulesComponent extends PageRender {
    public rules: any[] = [];
    public isLoading: boolean = true;
    public newPriceRuleModel: PriceRule = new PriceRule();
    public site: Site = {} as Site;
    public reload: number = 0;
    public search = new GridWrapperSearch({ properties: ['name'] });
    public priceRuleColumns = [
        { field: 'name', title: 'Naam', filterable: false, cell: this.renderPriceRuleLink },
        { field: 'siteIds', title: 'Parken gekoppeld', cell: this.renderSites, sortable: false },
        { field: 'isActive', title: 'Is actief', filter: 'bool', cell: this.renderIsActive, width: 150 },
        { title: 'Acties', cell: this.renderActions, field: 'actions', width: 150, sortable: false },
    ];
    public $refs!: {
        newPriceRuleModal: BModal;
    };

    public settings = {
        valueField: 'eventTypeId',
        labelField: 'name',
        options: this.rules,
        openOnFocus: true,
        searchField: ['name'],
        placeholder: 'Selecteer een type',
    };

    public async created() {
        await sitesModule.fetchSitesIfNeeded();
        this.rules = await this.loadPriceRules();
        this.isLoading = false;
    }

    public get sites() {
        return sitesModule.sites;
    }

    public async loadPriceRules() {
        const [err, response] = await to(priceRuleService.getPriceRules());
        if (err) {
            this.showError('Business regels ophalen mislukt');
        }
        return response.data;
    }

    public addPriceRule() {
        const params = {
            priceRuleKey: 'nieuw',
        };

        $router.push({
            name: 'price-rule',
            params,
        });
    }

    public renderActions(h, a, row): any {
        const item = row ? row.dataItem : {};
        const actions = [
            { title: 'Bewerk', function: this.editRule },
            { title: 'Verwijder', function: this.deleteRule },
        ];

        const props = { actions, item };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderPriceRuleLink(h: any, a, row) {
        const params = {
            priceRuleKey: row.dataItem.priceRuleId,
        };

        const route = $router.resolve({
            name: 'price-rule',
            params,
        });

        const props = {
            text: row.dataItem.name,
            url: route.href,
        };

        return h(Vue.component('grid-router-link'), { props });
    }

    public async deleteRule(item) {
        this.showPending('Prijsregel verwijderen...');
        const [err, response] = await to(priceRuleService.deletePriceRule(item.priceRuleId));
        if (err) {
            return this.clearAndShowError('Mislukt om de prijsregel te verwijderen', err);
        }
        this.rules = await this.loadPriceRules();
        this.reload++;

        this.clearAndShowSuccess('Prijsregel verwijderd');
        return response.data;
    }

    public editRule(item) {
        const params = {
            priceRuleKey: item.priceRuleId,
        };

        $router.push({
            name: 'price-rule',
            params,
        });
    }

    public renderSystemPriceRule(h: any, a: any, row: any): any {
        return h('td', [typeof row.dataItem.siteId !== 'undefined' ? 'Nee' : 'Ja']);
    }

    public renderIsActive(h: any, a: any, row: any) {
        return h('td', [row.dataItem.isActive ? 'Ja' : 'Nee']);
    }

    public renderSiteName(h: any, a: any, row: any): any {
        const site = this.sites.find((s) => row.dataItem.siteIds.indexOf(s.siteId) > -1);

        return h('td', [typeof site === 'undefined' ? '-' : site.name]);
    }

    public renderSites(h, a, row: any) {
        const self = this;
        const sites = this.sites.filter((s) => row.dataItem.siteIds.indexOf(s.siteId) > -1);
        if (!sites || sites.length === 0) {
            return h('td', `Geen parken`);
        }

        if (sites.length === 1) {
            return h('td', `${sites[0].name}`);
        }

        const props = {
            text: `${sites.length} parken`,
            items: sites,
            route: 'site',
            textField: 'name',
            valueField: 'siteId',
            callback(item) {
                self.$router.push({
                    name: 'site',
                    params: {  siteId: item.siteId.toString(), siteKey: item.siteKey },
                });
            },
        };

        return h(Vue.component('grid-select'), { props });
    }
}
