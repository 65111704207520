import EventType from './EventType';
import moment from 'moment/min/moment.min.js';
import { dateHelper } from '@/main';

export default class RecreapiEvent {
    public eventId: number = 0;
    public name: string = '';
    public eventType: EventType;
    public eventTypeId: number;

    public date: Date;
    public endDate: Date;
    constructor(item?: Partial<RecreapiEvent>) {
        if (item) {
            Object.assign(this, item);
        }
    }
}
