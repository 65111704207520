import Component from 'vue-class-component';
import { VueEditor } from 'vue2-editor';
import to from 'await-to-js';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue/src/components/modal';
import BasePage from '@/models/BasePage';
import { Member } from '@/models/Member';
import { userRoleService, userService } from '@/main';
import { sitesModule } from '@/store/modules/site';

@Component({
    components: {
        VueEditor,
    },
})
export default class RolesView extends BasePage {
    public users: Member[] = [];
    public roles: any[] = [{ id: 'SiteAdmin', description: 'Administrator' }];

    public modalUser: Member = new Member();
    public selectedUser: Member = new Member();
    public oldRoles: any[] = [];

    public member: number = 0;
    public selectedSites: number[] = [];
    public selectedMember: Member;

    public gridColumns: any[] = [
        { field: 'firstName', title: 'Voornaam', width: 200 },
        { field: 'lastName', title: 'Achternaam', width: 200 },
        // { field: 'email', title: 'E-mailadres' },
        { field: 'roles', title: 'Rollen', cell: this.renderRoles },
        { cell: this.renderSites, title: 'Parktoegang' },
        { title: 'Bewerk', cell: this.renderActions, width: 80 },
    ];

    public $refs!: {
        rolesModal: BModal;
        archiveModal: BModal;
        siteAccessModal: BModal;
    };

    public async mounted() {
        await sitesModule.fetchSitesIfNeeded();

        const roles = await userRoleService.getPermissions();
        this.roles.push(...roles);
        this.users = await userService.getUsers();

        this.isLoading = false;
    }

    public get sites() {
        return sitesModule.sites;
    }

    public async goToInviteMember() {
        this.$router.push({ name: 'invite-member' });
    }

    public openRolesModal(user: Member) {
        this.modalUser = user;
        this.oldRoles = user.roles;
        this.$refs.rolesModal.show();
    }

    public openArchiveModal(user: Member) {
        this.selectedUser = user;
        this.$refs.archiveModal.show();
    }

    public get roleOptions() {
        return this.roles.map((role) => {
            return {
                text: role.description,
                value: role.id,
            };
        });
    }

    public renderRoles(h, a, row) {
        const translatedRoles = [];

        for (const role of row.dataItem.roles) {
            translatedRoles.push(this.roles.find((x) => x.id === role).description);
        }

        return h('td', translatedRoles.join(', '));
    }

    public async updateRoles() {
        this.showPending('Rollen updaten...');
        const removedRoles = [];
        const addedRoles = [];

        for (const role of this.oldRoles) {
            if (this.modalUser.roles.indexOf(role) === -1) {
                removedRoles.push(role);
            }
        }

        for (const role of this.modalUser.roles) {
            if (this.oldRoles.indexOf(role) === -1) {
                addedRoles.push(role);
            }
        }

        for (const addedRole of addedRoles) {
            const [err] = await to(userRoleService.setUserRole(this.modalUser.externalUserId, addedRole));
            if (err) {
                return this.clearAndShowError('Rollen updaten mislukt', err);
            }
        }

        for (const removedRole of removedRoles) {
            const [err] = await to(userRoleService.deleteUserRole(this.modalUser.externalUserId, removedRole));
            if (err) {
                return this.clearAndShowError('Rollen updaten mislukt', err);
            }
        }

        this.clearAndShowSuccess('Rollen geupdatet');
        this.$refs.rolesModal.hide();

        this.isLoading = true;
        this.users = await userService.getUsers();
        this.isLoading = false;
    }

    public renderActions(h, a, row: any): any {
        const actions = [
            { title: 'Parktoegang wijzigen', function: this.showSiteAccessModal },
            { title: 'Rollen wijzigen', function: this.openRolesModal },
            { title: 'Archiveer', function: this.openArchiveModal },
        ];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public showSiteAccessModal(item: Member) {
        this.$refs.siteAccessModal.show();
        this.selectedMember = item;
        this.member = item.externalUserId;
        this.selectedSites = this.selectedMember.sites.map((x) => x.siteId);
    }

    public async archiveUser() {
        const user = { ...this.selectedUser };
        this.showPending(`${user.email} archiveren...`);
        const success = await userService.deleteUser(user.id);

        if (success) {
            this.clearAndShowSuccess(`${user.email} successvol gearchiveerd.`);
        } else {
            this.clearAndShowError(`Mislukt om ${user.email} te archiveren.`);
        }
        this.selectedUser = new Member();
        this.$refs.archiveModal.hide();

        this.isLoading = true;
        this.users = await userService.getUsers();
        this.isLoading = false;
    }

    public async saveSites() {
        this.showPending('Parktoegang opslaan..');
        const [err, response] = await to(userService.saveSites(this.selectedMember.id, this.selectedSites));
        if (err) {
            return this.clearAndShowError('Mislukt om parktoegang op te slaan.', null);
        }

        this.clearAndShowSuccess('Parktoegang succesvol opgeslagen.');

        const member = this.users.find((m) => m.id === this.selectedMember.id);
        member.siteIds = this.selectedSites;
        member.sites = this.sites.filter((site) => member.siteIds.indexOf(site.siteId) > -1);

        this.$refs.siteAccessModal.hide();
    }

    private renderSites(h, a, row: any) {
        const self = this;
        const sites = row.dataItem.sites;
        if (!sites || sites.length === 0) {
            return h('td', `Geen parken`);
        }

        if (sites.length === 1) {
            return h('td', `${sites[0].siteName}`);
        }

        const props = {
            text: `${sites.length} parken`,
            items: sites,
            route: 'site',
            textField: 'siteName',
            valueField: 'siteId',
            callback(item) {
                self.$router.push({
                    name: 'site',
                    params: { siteId: item.siteId.toString(), siteKey: item.siteKey },
                });
            },
        };

        return h(Vue.component('grid-select'), { props });
    }
}
