import { FeatureInterface } from './Interfaces';

export default class Feature implements FeatureInterface {
    public featureId: number;
    public name: string;
    public amount: number;
    public isSystemFeature: boolean;
    public featureGroupId: number;
    public featureGroup: any;
}
