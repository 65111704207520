import PageRender from '../../models/PageRender';
import { bankHolidayService, dateHelper } from '@/main';
import to from 'await-to-js';
import RecreapiEvent from '../../models/RecreapiEvent';
import { AxiosResponse } from 'axios';
import Component from 'vue-class-component';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import EventType from '../../models/EventType';
import { KendoGrid } from '@progress/kendo-grid-vue-wrapper';
import { functions } from '../../helpers/functions';
import { Watch } from 'vue-property-decorator';
import moment from 'moment/min/moment.min.js';

@Component
export default class BankHolidaysComponent extends PageRender {
    public bankHolidays: RecreapiEvent[] = null;
    public bankHolidaysPrepared: any = [];
    public importedBankHolidays: any[] = [];
    public types: EventType[] = null;
    public isLoading: boolean = true;
    public isUploadingCsv: boolean = false;
    public newEventModel: RecreapiEvent = new RecreapiEvent({});
    public startDate: any = new Date();
    public endDate: any = new Date();
    public eventTypeObject: any = null;

    public $refs!: {
        newEventModal: BModal;
        importEventsModal: BModal;
        eventGrid: KendoGrid;
    };

    public gridColumns = [
        { field: 'name', title: 'Naam' },
        { field: 'type', title: 'Type', width: '200px' },
        { field: 'date', title: 'Startdatum', width: '300px' },
        { field: 'endDate', title: 'Einddatum', width: '300px' },
        { cell: this.renderActions, title: 'Actions', width: '150px' },
    ];

    public bankHolidayColumns = [
        { field: 'name', with: '150px' },
        { field: 'eventType.name', title: 'Type', width: '100px' },
        { field: 'startDate', title: 'Startdatum', width: '100px', cell: this.renderStartDate },
        { field: 'endDate', title: 'Einddatum', width: '100px', cell: this.renderEndDate },
    ];

    public settings = {
        valueField: 'eventTypeId',
        labelField: 'name',
        options: this.types,
        openOnFocus: true,
        searchField: ['name'],
        placeholder: 'Selecteer een type',
    };

    @Watch('eventTypeObject')
    public onEventTypeChange(val, oldVal) {
        if (val) {
            this.newEventModel.eventTypeId = val.eventTypeId;
        }
    }

    public async created() {
        const self = this;
        const [bankErr, bankHolidays] = await to<AxiosResponse<RecreapiEvent[]>>(bankHolidayService.getBankHolidays());
        if (bankErr) {
            this.showFailedResponse('Mislukt om feestdagen op te halen', bankErr);
        }

        this.bankHolidays = [];
        bankHolidays.data.forEach((item) => {
            const holiday = new RecreapiEvent(item);
            self.bankHolidays.push(holiday);
            self.bankHolidaysPrepared.push({
                eventId: holiday.eventId,
                name: holiday.name,
                type: holiday.eventType.name,
                date: dateHelper.format(holiday.date, 'DD-MM-YYYY'),
                endDate: holiday.endDate ? dateHelper.format(holiday.endDate, 'DD-MM-YYYY') : '-',
            });
        });

        const [err, types] = await to<AxiosResponse<EventType[]>>(bankHolidayService.getTypes());
        if (err) {
            this.showFailedResponse('Could not load event types.', err);
        }

        this.settings.options = this.types = types.data;
        this.isLoading = false;
    }

    public renderActions(h, a, row: any): any {
        const actions = [
            { title: 'Bewerk', function: this.editEvent },
            { title: 'Verwijder', function: this.deleteEvent },
        ];

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public async deleteEvent(item) {
        const self = this;
        const [err, types] = await to<AxiosResponse<EventType[]>>(bankHolidayService.deleteBankHolidays([item.eventId]));
        if (err) {
            this.showFailedResponse('Mislukt om item te verwijderen', err);
            return;
        }
        const event = this.bankHolidaysPrepared.findIndex((e: RecreapiEvent) => {
            return e.eventId === item.eventId;
        });

        this.bankHolidaysPrepared.splice(event, 1);
    }

    public editEvent(item) {
        this.newEventModel = this.bankHolidays.find((holiday) => {
            return holiday.eventId === item.eventId;
        }) as RecreapiEvent;
        this.newEventModel.eventId = item.eventId;
        this.newEventModel.eventTypeId = this.newEventModel.eventType.eventTypeId;
        this.eventTypeObject = this.newEventModel.eventType;
        this.startDate = moment(this.newEventModel.date, 'YYYY-MM-DD').toDate();
        this.endDate = moment(this.newEventModel.endDate, 'YYYY-MM-DD').toDate();

        this.$refs.newEventModal.show();
    }

    public showAddEventModal() {
        this.$refs.newEventModal.show();
    }

    public async addEvent() {
        const self = this;
        const event = { eventId: null, eventType: null, name: null, date: null, endDate: null };
        event.eventId = this.newEventModel.eventId;
        event.eventType = this.types.find((type) => type.eventTypeId === self.newEventModel.eventTypeId);
        event.name = this.newEventModel.name;
        event.date = moment(this.startDate, 'DD-MM-YYYY').add('h', 2);

        if (this.endDate) {
            event.endDate = moment(this.endDate, 'DD-MM-YYYY').add('h', 2);
        }

        const [err, response] = await to(bankHolidayService.saveBankHolidays([event]));
        if (err) {
            if (response && response.status === 403) {
                this.showFailedResponse('Niet toegestaand', err);
            }

            this.showFailedResponse('Mislukt om item op te slaan', err);
            return;
        }

        if (!event.eventId) {
            const holiday = new RecreapiEvent(response.data[0]);
            self.bankHolidays.push(holiday);
            self.bankHolidaysPrepared.push({
                eventId: holiday.eventId,
                name: holiday.name,
                type: holiday.eventType.name,
                date: dateHelper.format(holiday.date, 'DD-MM-YYYY'),
                endDate: holiday.endDate ? dateHelper.format(holiday.endDate, 'DD-MM-YYYY') : '-',
            });
        } else {
            const newEventModel = this.bankHolidaysPrepared.find((holiday) => {
                return holiday.eventId === event.eventId;
            }) as RecreapiEvent;

            newEventModel.name = response.data[0].name;
            newEventModel.date = dateHelper.format(response.data[0].date, 'DD-MM-YYYY');
            newEventModel.endDate = newEventModel.endDate ? dateHelper.format(response.data[0].endDate, 'DD-MM-YYYY') : '-';
            newEventModel.eventType = this.types.find((type) => type.eventTypeId === response.data[0].eventTypeId);
        }

        this.$refs.newEventModal.hide();
    }

    public showImportModal() {
        this.$refs.importEventsModal.show();
    }

    public async importEvents() {
        const self = this;
        self.isUploadingCsv = true;

        const postModel = self.importedBankHolidays.map((element) => {
            const event = { eventType: null, name: null, date: null, endDate: null };
            event.eventType = element.eventType;
            event.name = element.name;
            event.date = moment(element.startDate, 'DD-MM-YYYY').add('h', 2);

            if (element.endDate) {
                event.endDate = moment(element.endDate, 'DD-MM-YYYY').add('h', 2);
            }

            return event;
        });

        const [err, response] = await to(bankHolidayService.saveBankHolidays(postModel));
        if (err) {
            self.showFailedResponse('Mislukt om items op te slaan', err);
            return;
        }

        response.data.forEach((item) => {
            const holiday = new RecreapiEvent(item);
            self.bankHolidays.push(holiday);
            self.bankHolidaysPrepared.push({
                eventId: holiday.eventId,
                name: holiday.name,
                type: holiday.eventType.name,
                date: dateHelper.format(holiday.date, 'DD-MM-YYYY'),
                endDate: holiday.endDate ? dateHelper.format(holiday.endDate, 'DD-MM-YYYY') : '-',
            });
        });

        this.$refs.importEventsModal.hide();
    }

    public async loadCSV(e: any) {
        const self = this;
        await functions.loadCSV(e, self, 'importedBankHolidays');
    }

    public renderStartDate(h, tdElement, row) {
        return h('td', [moment(row.dataItem.startDate).format('DD-MM-YYYY')]);
    }
    public renderEndDate(h, tdElement, row) {
        return h('td', [moment(row.dataItem.endDate).format('DD-MM-YYYY')]);
    }
}
