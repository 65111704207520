import moment from 'moment/min/moment.min.js';
import { KendoGrid } from '@progress/kendo-grid-vue-wrapper';
import { bankHolidayService } from '@/main';
import { AxiosResponse } from 'axios';
import EventType from '../models/EventType';
import to from 'await-to-js';
import RecreapiEvent from '../models/RecreapiEvent';

enum CSVHeaders {
    Name = 'name',
    EventType = 'eventType',
    StartDate = 'startDate',
    EndDate = 'endDate',
}

class Functions {
    public getCommunicationChannel(channels: any, channelName: string) {
        if (channels && channels.length) {
            const filteredChannel = channels.filter((channel: any) => channel.channelName === channelName);

            if (filteredChannel && filteredChannel.length) {
                return filteredChannel[0].value;
            }
        }
        return 'N/A';
    }

    public async loadCSV(e: any, object: any, property: string) {
        const self = this;

        if ((window as any).FileReader) {
            const reader = new FileReader();
            reader.readAsText(e.target.files[0]);
            // Handle errors load
            reader.onload = async (event) => {
                const csv = (event.target as any).result;
                object[property] = await self.csvJSON(csv);
            };
            reader.onerror = (evt) => {
                if ((evt.target as any).error.name === 'NotReadableError') {
                    alert(`Canno't read file !`);
                }
            };

            reader.onloadend = () => {
                return object;
            };
        } else {
            alert('FileReader are not supported in this browser.');
        }
    }

    public async csvJSON(csv, delimiter = ';') {
        const lines = csv.split('\n');
        const result = [];
        const headers = lines[0].split(delimiter).map((header) => {
            return header.replace('\r', '');
        });

        const [err, types] = await to<AxiosResponse<EventType[]>>(bankHolidayService.getTypes());

        for (let lineIndex = 1; lineIndex < lines.length; lineIndex++) {
            const line = lines[lineIndex];
            const obj = {};
            const currentline = line.split(delimiter);
            let invalidRow = false;

            for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
                const header = headers[headerIndex];
                let cell = currentline[headerIndex];

                if (cell) {
                    cell = cell.replace('\'', '').replace('\r', '');
                }

                let value: any;
                // headers
                // name;eventType;startDate;endDate
                switch (header) {
                    case CSVHeaders.EndDate:
                        if (cell) {
                            value = moment(cell, 'DD/MM/YYYY').toDate();
                            break;
                        }
                        value = '';
                        break;
                    case CSVHeaders.StartDate:
                        value = moment(cell, 'DD/MM/YYYY').toDate();
                        break;
                    case CSVHeaders.EventType:
                        value = types.data.find((item) => item.name === cell );
                        break;
                    default:
                        if (cell === '') {
                            invalidRow = true;
                        }
                        value = cell;
                        break;
                }

                if (invalidRow) {
                    break;
                } else {
                    obj[header] = value;
                }
            }

            if (invalidRow) {
                invalidRow = false;
                continue;
            }

            result.push(new RecreapiEvent(obj));
        }
        return result; // JavaScript object
    }

    public downloadFile(response: any, filename: any): void {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'text/csv' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(newBlob);
        //     return;
        // }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = filename + '.csv';
        link.click();
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    public refreshGrids(refs: any): void {
        const widgets = [];
        for (const ref in refs) {
            if (ref.toLowerCase().indexOf('grid') > -1) {
                const grid = refs[ref] as KendoGrid;
                if (typeof grid !== 'undefined') {
                    widgets.push(grid.kendoWidget() as kendo.ui.Grid);
                }
            }
        }

        setTimeout(() => {
            widgets.forEach((item) => { item.refresh(); });
        }, 1);
    }

    public setCookie(name: string, value: any, days: any): void {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    public getCookie(name: string): any {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    public eraseCookie(name: string): void {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}

export const functions = new Functions();
