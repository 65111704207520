import Component from 'vue-class-component';
import { financeAccountService, productService } from '@/main';
import Vue from 'vue';
import to from 'await-to-js';
import BasePage from '../../models/BasePage';
import { BModal } from 'bootstrap-vue';
import Site from '../../models/site';
import Product from '@/models/Product';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import { sitesModule } from '@/store/modules/site';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        selectedSites: { required },
        financeAccount: { name: { required }, accountNumber: { required } },
    },
})
export default class FinanceAccountsPage extends BasePage implements IVuelidate<any> {
    public financeAccounts: any[] = [];
    public isLoading: boolean = true;
    public financeAccount: any = { name: '', accountNumber: '', financeAccountId: null, siteIds: null };
    public sites: Site[] = [];
    public selectedSites: number[] = [];
    public oldSelectedSites: number[] = [];
    public products: Product[];

    public gridColumns = [
        { field: 'name', title: 'Naam' },
        { field: 'accountNumber', title: 'Accountnummer' },
        { field: 'siteIds', title: 'Park', cell: this.renderSites },
        { title: 'Acties', cell: this.renderActions, filterable: false, width: '200px' },
    ];

    public $refs!: {
        newFinanceAccount: BModal;
    };

    public async mounted() {
        await sitesModule.fetchSitesIfNeeded();
        this.sites = sitesModule.sites;
        this.financeAccounts = await this.loadFinanceAccounts();
        this.isLoading = false;
    }

    public async loadFinanceAccounts() {
        const [err, response] = await to(financeAccountService.getAccounts());
        if (err) {
            this.showError('Mislukt om de facturen op te halen');
        }
        return response.data;
    }

    public deleteAccount() {
        throw new Error('Method not implemented');
    }

    public async addNewFinanceAccount(account: any) {
        this.$refs.newFinanceAccount.show();

        if (account) {
            this.financeAccount = account;
            this.selectedSites = account.siteIds;
            this.oldSelectedSites = account.siteIds;

            const [err, response] = await to(productService.getProducts(null, null, this.financeAccount.financeAccountId));
            this.products = response.data;
        } else {
            this.financeAccount = { name: '', accountNumber: '', financeAccountId: null, siteIds: [] };
        }
    }

    public async saveFinanceAccount() {
        const self = this;
        self.showPending('Grootboekrekening opslaan..');

        this.financeAccount.siteIds = this.selectedSites;

        if (!this.validateObject('selectedSites') || !this.validateObject('financeAccount')) {
            return this.clearAndShowError('Niet alle verplichte velden zijn ingevuld.');
        }

        if (this.financeAccount.financeAccountId) {
            const difference = this.oldSelectedSites.filter((x) => !self.selectedSites.includes(x));
            const products = this.products.filter((product) => product.siteIds.filter((id) => difference.includes(id)).length > 0);

            if (products && products.length > 0) {
                return self.clearAndShowError('Grootboekrekening wordt nog gebruikt in een product binnen een park die je ontkoppeld, opslaan niet mogelijk.');
            }

            const [err, response] = await to(financeAccountService.saveFinanceAccount(this.financeAccount));
            if (err) {
                return self.clearAndShowError('Grootboekrekening opslaan mislukt.', null);
            }

            this.financeAccount.siteIds = response.data.siteIds;
        } else {
            const [err, response] = await to(financeAccountService.createFinanceAccount(this.financeAccount));
            if (err) {
                return self.clearAndShowError('Grootboekrekening opslaan mislukt.', null);
            }

            this.financeAccounts.push(response.data);
        }

        this.clearAndShowSuccess('Grootboekrekening opslaan gelukt.');
        this.dirty = false;
        this.$refs.newFinanceAccount.hide();
    }

    public renderSites(h, a, row: any) {
        const self = this;
        const sites = this.sites.filter((site) => row.dataItem.siteIds.indexOf(site.siteId) > -1);
        if (!sites || sites.length === 0) {
            return h('td', `Geen parken`);
        }

        if (sites.length === 1) {
            return h('td', `${sites[0].name}`);
        }

        const props = {
            text: `${sites.length} parken`,
            items: sites,
            route: 'site',
            textField: 'name',
            valueField: 'siteId',
            callback(item) {
                self.$router.push({
                    name: 'site',
                    params: {  siteId: self.siteId.toString(), siteKey: self.siteKey },
                });
            },
        };

        return h(Vue.component('grid-select'), { props });
    }

    private renderActions(h: any, a, row) {
        const status = row.dataItem.status;
        const actions = [/*{ title: 'Verwijder account', function: this.deleteAccount }, */ { title: 'Bewerk account', function: this.addNewFinanceAccount }];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }
}
