import { dictionaryHelper,  discountService } from '@/main';
import Component from 'vue-class-component';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import to from 'await-to-js';
import Site from '../../models/Site';
import { Watch } from 'vue-property-decorator';
import moment from 'moment/min/moment.min.js';
import DiscountCode from '../../models/DiscountCode';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import BasePage from '@/models/BasePage';
import { sitesModule } from '@/store/modules/site';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        selectedSites: { required },
        newCampaignModel: { name: { required }, codes: { required }, validUntil: { required }, usagesLeft: { required } },
        addCodesCampaignModel: { name: { required }, codes: { required }, validUntil: { required }, usagesLeft: { required } },
    },
})
export default class DiscountsComponent extends BasePage implements IVuelidate<any> {
    public discountGroups: any[] = [];
    public isLoading: boolean = true;
    public dictionary = dictionaryHelper;
    public financeAccounts: any[] = [];
    public codesString: string = '';
    public selectedSites: number[] = [];
    public sites: Site[] = [];
    public $refs!: {
        newCampaignModal: BModal;
        addCodesToCampaignModal: BModal;
        discountCodeGrid: any;
        editCode: BModal;
    };

    public newCampaignModel: any = { name: '', codes: [], validUntil: null, usagesLeft: 1, SiteIds: [] };
    public addCodesCampaignModel: any = { name: '', codes: [], validUntil: null, usagesLeft: 1, siteIds: [] };
    public codeModel: any = {} as DiscountCode;
    public codeModelDate: Date = new Date();

    public discountCodesColumns = [
        { field: 'code', title: 'Code', filterable: false },
        { field: 'issued', title: 'Uitgegeven', cell: this.renderIssued, filterable: false },
        { field: 'used', title: 'Aantal keer te gebruiken', cell: this.renderAantal },
        { field: 'validUntil', cell: this.renderDate, title: 'Geldig tot' },
        { cell: this.renderActions, title: 'Acties', width: 150 },
    ];

    @Watch('codesString', { deep: true, immediate: true })
    public OnCodeStringChange(val, oldVal) {
        this.newCampaignModel.codes = val.split(',').filter((value) => value !== '');
    }

    public async created() {
        await this.loadDiscounts();
        await sitesModule.fetchSitesIfNeeded();
        this.sites = sitesModule.sites;
        this.isLoading = false;
    }

    public async mounted() {
        const self = this;
        this.$nextTick(() => {
            window.addEventListener('resize', self.fitDiscountGrid);
        });
    }

    public async loadDiscounts() {
        const [err, response] = await to(discountService.getDiscounts());
        if (err) {
            this.showError('Kortingscodes ophalen mislukt');
        }
        return (this.discountGroups = response.data);
    }

    public renderActions(h: any, a: any, row: any) {
        const item = row.dataItem;

        const actions = [
            { title: 'Bewerk', function: this.showUpdateCode },
            { title: 'Deactiveer', function: this.deleteCode },
        ];

        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderIssued(h: any, a: any, row: any) {
        return h('td', row.dataItem.Issued ? 'Ja' : 'Nee');
    }

    public renderDate(h: any, a: any, row: any) {
        if (typeof row.dataItem.usagesLeft !== 'undefined' && row.dataItem.usagesLeft === 0) {
            return h('td', '-');
        }

        return h('td', moment(row.dataItem.validUntil).format('YYYY-MM-DD'));
    }

    public renderAantal(h: any, a: any, row: any) {
        if (typeof row.dataItem.usagesLeft !== 'undefined') {
            return h('td', row.dataItem.usagesLeft);
        }

        return h('td', 'Onbeperkt');
    }

    public async addNewCampaign() {
        this.showPending('Nieuwe campagne aanmaken..');

        if (!this.validateObject('selectedSites') || !this.validateObject('newCampaignModel')) {
            return this.clearAndShowError('Niet alle verplichte velden zijn ingevuld.');
        }

        const date = moment(this.newCampaignModel.validUntil, 'YYYY-MM-DD');
        this.newCampaignModel.validUntil = date.format('YYYY-MM-DD');
        this.newCampaignModel.siteIds.push(...this.selectedSites);

        const [err, response] = await to(discountService.addCampgaign(this.newCampaignModel));
        if (err) {
            this.clearAndShowError('Campagne aanmaken mislukt', err);
            return;
        }

        this.clearAndShowSuccess('Campagne aangemaakt.');

        this.discountGroups.push(response.data);

        this.$refs.newCampaignModal.hide();
    }

    public async saveGroup(groupId) {
        this.showPending('Campagne naam updaten..');

        const [err, response] = await to(discountService.updateGroup(this.discountGroups.find((x) => x.discountGroupId === groupId)));

        if (err) {
            this.clearAndShowError('Campagne naam updaten mislukt', err);
            return;
        }

        this.clearAndShowSuccess('Campagne naam aangepast.');
        this.editMode = false;
    }

    public async showNewCampaignModal() {
        this.newCampaignModel = { name: '', codes: [], validUntil: null, usagesLeft: 1, siteIds: [] };
        this.codesString = '';
        this.$refs.newCampaignModal.show();
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.fitDiscountGrid);
    }

    public async showUpdateCode(code) {
        this.codeModel = code;
        this.codeModelDate = moment(code.validUntil, 'YYYY-MM-DD').toDate();
        this.$refs.editCode.show();
    }

    public async updateCampaign(groupId) {
        const group = this.discountGroups.find((g) => g.discountGroupId === groupId);
        this.newCampaignModel.name = group.name;
        this.newCampaignModel.siteIds = this.selectedSites = group.siteIds;

        this.newCampaignModel.discountGroupId = group.discountGroupId;

        this.codesString = '';

        this.$refs.addCodesToCampaignModal.show();
    }

    public async updateCode() {
        this.showPending('Code updaten...');

        const date = moment(this.codeModelDate, 'YYYY-MM-DD');
        this.codeModel.validUntil = date.format('YYYY-MM-DD');

        const [err, response] = await to(discountService.updateCode(this.codeModel));
        if (err) {
            this.clearAndShowError('Code deactiveren mislukt', err);
            return;
        }

        this.clearAndShowSuccess('Code gedeactiveerd.');
        const group = this.discountGroups.find((g) => g.discountGroupId === response.data.discountGroupId);
        const updatedCode = group.discountCodes.find((c) => c.discountCodeId === response.data.discountCodeId);
        updatedCode.usagesLeft = response.data.usagesLeft;

        this.$refs.editCode.hide();
    }

    public async deleteCode(code) {
        this.showPending('Code deactiveren...');
        const [err, response] = await to(discountService.deactivateCode(code.discountCodeId));
        if (err) {
            this.clearAndShowError('Code deactiveren mislukt', err);
            return;
        }

        this.clearAndShowSuccess('Code gedeactiveerd.');

        const group = this.discountGroups.find((g) => g.discountGroupId === response.data.discountGroupId);
        const updatedCode = group.discountCodes.find((c) => c.discountCodeId === response.data.discountCodeId);
        updatedCode.usagesLeft = 0;
    }

    public async addCodesToCampaign() {
        this.showPending('Campagne opslaan..');

        if (!this.validateObject('newCampaignModel')) {
            return this.clearAndShowError('Niet alle verplichte velden zijn ingevuld.');
        }

        const date = moment(this.newCampaignModel.validUntil, 'YYYY-MM-DD');
        this.newCampaignModel.validUntil = date.format('YYYY-MM-DD');
        this.newCampaignModel.siteIds = this.selectedSites;

        const [err, response] = await to(discountService.addCodesToCampgaign(this.newCampaignModel));
        if (err) {
            this.clearAndShowError('Campagne opslaan mislukt', err);
            return;
        }

        this.clearAndShowSuccess('Campagne opgeslagen.');
        const group = this.discountGroups.find((g) => g.discountGroupId === response.data.discountGroupId);
        group.discountCodes = response.data.discountCodes;
        this.selectedSites = group.siteIds = response.data.siteIds;
        this.$refs.addCodesToCampaignModal.hide();
    }

    public getSiteName(siteId) {
        return this.sites.find((site) => site.siteId === siteId).name;
    }

    private fitDiscountGrid(): any {
        this.fitElement(this.$refs.discountCodeGrid.$el);
    }
}
